.logo{
    height: 60px;
    padding: 50px;
    cursor: pointer;
}


.logo path{
    animation:line-animation 3s ease forwards;
}

.logo:hover path{
    animation:line-animation 3s ease forwards;
}

.logo:hover path:nth-child(1){
    stroke-dasharray: 278;
    stroke-dashoffset: 278;
}
.logo:hover path:nth-child(2){
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
}

.logo path:nth-child(1){
    stroke-dasharray: 278;
    stroke-dashoffset: 278;
}

.logo path:nth-child(2){
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
}




@keyframes line-animation{
    to{
        stroke-dashoffset: 0;
    }
}

.navButton{
    height: 50px;
    padding: 50px;
    cursor: pointer;
}
.navButton:hover{
    cursor: pointer;
}

.flexHeader{
    display: flex;
    margin: 0;
    background-color: aliceblue;
}

.flexHeaderBoxes{
    flex: 1;
}

.navFlexBox{
    display: flex;
    justify-content: right;
    padding-right: 150px;
}

.HeaderLinkBox{
    display: flex;
    border:.5px solid rgb(74, 17, 128);
    margin: 30px;
    padding: 50px;
    flex-grow: 4;
    border-radius: 4px;
    top: -300;
    transition: top 5s linear;
    visibility: visible;
    transition: 1s transform;
}


.showNav{
    transform: translateY(-50vh);
}



.hideRegNav{
    visibility: hidden;
}


.HeaderLinks{
    cursor: pointer;
    flex: 1;
    justify-content: space-around;
}

.HeaderLinkText{
    color:  rgb(74, 17, 128);
    font-weight: 500;
    text-decoration: none;
    font-size: 22px;
}

.HeaderLinkText:hover{
    border-bottom: 3px solid;
    animation-name: navAnimation;
    animation-duration: 3s;
}

.menuBtn{
    border: 0;
    background-color: transparent;
}


#mobileNavBox{
    position: absolute;
    display: flex;
    z-index: 3;
    top: 0;
    right: 0;
    background-color: rgb(227, 208, 246);
    height: 100%;
    width: 100%;
    padding: 0px;
    margin: 0px;
}

@media screen and (max-width: 1300px) {
    

    .flexHeader{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        margin: 0;
    }


    .HeaderLinkBox{
       flex-direction: column;
       border: none;
       padding: 200px 0px;
       margin: 0;
       flex-shrink: .5;
       background-color: transparent;
      
    }

    .HeaderLinks{
        text-align: left
       
    }

    .HeaderLinkText{
        font-size: 30px;
        padding-left: 150px;
        
    }

    .navFlexBox{
        position: absolute;
        top: 0;
        right: 0;
        padding-right: 30px;
    }


    .logo {
        position: absolute;
        top: 0;
        left:0;
    }
}



.aboutImgBox{
    
    justify-content: center;
}

.aboutImg{
    height: 400px;
    border: 2px solid transparent;
    border-radius: 30px;
    padding-top: 50px;
}

.aboutTextBox{
    padding-top: 30px;
    text-align: left;
    font-size: 23px;
    line-height: 40px;
}

 
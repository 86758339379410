body{
    
    background-color: aliceblue  !important;
    position: relative;
    margin: auto;
    width: 100vw;
    display: block;
    min-width: fit-content;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#root{
    margin: 0;
}

@font-face {
    font-family: 'Aubrey';
    src: url('./AUBREY1__.TTF');
}
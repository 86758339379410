.homebg{
    background-color: aliceblue;
    width: 100vw;
    
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-30px);} 
    60% {transform: translateY(-15px);} 
 }

 @-webkit-keyframes bounce { 
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);} 
    40% {-webkit-transform: translateY(-30px);} 
    60% {-webkit-transform: translateY(-15px);} 
 } 

p{
    margin: 0;
}

.title{
    font-size: 30px;
    text-align: center;
    padding: 30px;
    line-height: 50px;
    

}
.titleOne{
    font-size: 60px;
    font-family: 'Aubrey';
    display: inline-block;
}

.titleOne:hover{
    -webkit-animation-duration: 1s;
    animation-duration: 1s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both;
    -webkit-animation-name: bounce; 
    animation-name: bounce; 
}

.titleTwo{
    color: purple;
    font-size: 35px;
    font-weight: 400;
    display: inline-block;
}

.titleTwo:hover{
    -webkit-animation-duration: 1s;
    animation-duration: 1s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both;
    -webkit-animation-name: bounce; 
    animation-name: bounce; 
}

.titleThree{
    color: purple;
    font-size: 30px;
    display: inline-block;
}

.titleThree:hover{
    -webkit-animation-duration: 1s;
    animation-duration: 1s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both;
    -webkit-animation-name: bounce; 
    animation-name: bounce; 
}

.arrowBox{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.arrowImg{
    height: 150px;
}


.imgBox{
    display: flex;
    justify-content: center;
    padding: 100px;
}

.projectImg{
    height: 300px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.projectTextBox{
    padding: 40px;
    flex: 1;
}

.projectTextBox a{
    text-decoration: none;
    font-weight: 500;
    color: purple;
}

.projectTextBox a:hover{
    color:blueviolet;
}

h1{
    line-height: 2px;
}

.react-pdf__Page.pdf-page { display: flex; justify-content: center; }

.projectsRow{
    padding-bottom: 50px;
    margin-bottom: 100px;
}